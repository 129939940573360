// extracted by mini-css-extract-plugin
export var hero = "xx_k4";
export var hero_content = "xx_p4";
export var hero_descr = "xx_m4";
export var hero_inner = "xx_n4";
export var hero_pic = "xx_s4";
export var hero_stats = "xx_b74";
export var hero_stats_item = "xx_b84";
export var hero_stats_title = "xx_cb4";
export var hero_stats_value = "xx_b94";
export var hero_subtitle = "xx_b64";
export var hero_title = "xx_q4";